import Cookies from 'js-cookie';
import { atom, DefaultValue } from 'recoil';

import { COOKIE_SUBSCRIPTIONS } from '@/lib/constants';
import { FeaturesInterface } from '@/types';
import { intersectObjectsLeft } from '@/utils/helpers';
import { setQayaqTestApiUrl } from '@/utils/store/api-url';
import { getStoredItem, removeStoredItem, setStoredItem } from '@/utils/store/local';

export const STORAGE_KEY = 'fluentFeatures';

export const defaultFeatures: FeaturesInterface = {
  debugRoutingApi: false,
  includeRailRoutes: false,
  useImprovedWaterSearch: false,
  pruneAirRoutes: true,
  pruneWaterRoutes: false,
  userAuthentication: true,
  pricingCharts: false,
  doNotTrack: false,
  surveyPopout: false,
  subscriptionsOn: true,
  enforceUsageLimits: true,
  quoteForm: true,
  useTestApi: false,
  canalViaAvoid: true,
  showAds: true,
  lineBasedScheduleSearch: true,
  showReliabilityScores: false,
  omniSearchTracking: false,
  batchShipmentImport: true,
  showSpotRates: false,
  showMarketRates: true,
  showFullCargoOptions: false,
};

// @NOTE: requires use of useRecoilValueAfterMount hook so it doesn't error on SSR
export const featuresState = atom({
  key: 'featuresState',
  default: defaultFeatures,
  effects: [
    ({ onSet, setSelf }) => {
      // On first load set cookie for the subscriptionsOn && useTestApi feature as
      // we need to access it from the server side
      Cookies.set(COOKIE_SUBSCRIPTIONS, defaultFeatures.subscriptionsOn.toString());
      setQayaqTestApiUrl(defaultFeatures.useTestApi);

      // Get cookie
      const storedData = getStoredItem(STORAGE_KEY);
      if (storedData) {
        // Update self with merged data
        setSelf(intersectObjectsLeft(defaultFeatures, storedData));
        // Update useTestApi cookie based on stored data
        setQayaqTestApiUrl(storedData.useTestApi);
      }

      onSet((changedFeatures) => {
        if (changedFeatures instanceof DefaultValue) {
          removeStoredItem(STORAGE_KEY);
        } else {
          setStoredItem(STORAGE_KEY, changedFeatures);
          // Update useTestApi cookie based on stored data
          setQayaqTestApiUrl(changedFeatures.useTestApi);
          // Also update cookie for the subscriptionsOn feature
          Cookies.set(COOKIE_SUBSCRIPTIONS, changedFeatures.subscriptionsOn.toString());
        }
      });
    },
  ],
});
